import { type ReactNode } from "react";

import { HorizontalScrollView } from "../../components/HorizontalScrollView";

interface ShiftBottomSheetCardsWrapperProps {
  children: ReactNode;
}

export function ShiftBottomSheetCardsWrapper(props: ShiftBottomSheetCardsWrapperProps) {
  const { children } = props;

  return (
    <HorizontalScrollView stretch sx={{ px: 5, height: "23.75rem" }}>
      {children}
    </HorizontalScrollView>
  );
}
