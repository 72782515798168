import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type ShiftRateNegotiation } from "@src/appV2/OpenShifts/RateNegotiation/types";
import { BookabilityDecision, type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";

import { DialogFooter } from "../../components/DialogFooter";
import { SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { BookShiftButton } from "./BookShiftButton";
import { RateNegotiationAction } from "./RateNegotiationAction";
import { ShiftNotBookableButton } from "./ShiftNotBookableButton";

interface ShiftBottomSheetSheetFooterProps {
  isLoading: boolean;
  isError: boolean;
  shiftId?: string;
  shiftOfferId?: string;
  workplaceId?: string;
  hasBlockingDocuments: boolean;
  modalState: UseModalState;
  bookabilityStatus: BookabilityStatusItem;
  isRateNegotiationEnabled?: boolean;
  shiftRateNegotiation?: ShiftRateNegotiation;
}

export function ShiftBottomSheetFooter(props: ShiftBottomSheetSheetFooterProps) {
  const {
    shiftId,
    shiftOfferId,
    workplaceId,
    modalState,
    bookabilityStatus,
    isLoading,
    isError,
    hasBlockingDocuments,
    isRateNegotiationEnabled,
    shiftRateNegotiation,
  } = props;

  const { navigateToModal } = useShiftModalsDataContext();

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED;

  const isShiftUnavailable = isError || (!isDefined(shiftId) && !isLoading);

  return (
    <DialogFooter
      orientation="vertical"
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOSED, { shiftId: shiftId ?? "" });
        modalState.closeModal();
      }}
    >
      {(!isBookable || isShiftUnavailable) && (
        <ShiftNotBookableButton
          isLoading={isLoading}
          bookabilityStatus={bookabilityStatus}
          hasBlockingDocuments={hasBlockingDocuments}
          shiftId={shiftId}
          isShiftUnavailable={isShiftUnavailable}
          onClose={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_UNAVAILABLE_CLOSED, {
              shiftId: shiftId ?? "",
            });
            modalState.closeModal();
          }}
        />
      )}

      {isDefined(shiftId) && isBookable && (
        <>
          <BookShiftButton
            shiftId={shiftId}
            shiftOfferId={shiftOfferId}
            workplaceId={workplaceId}
            onBook={() => {
              navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, { shiftId });
            }}
          />

          {isRateNegotiationEnabled && (
            <RateNegotiationAction rateNegotiation={shiftRateNegotiation} shiftId={shiftId} />
          )}
        </>
      )}
    </DialogFooter>
  );
}
