import { mergeSxProps } from "@clipboard-health/ui-react";
import type { Ref } from "react";
import { forwardRef } from "react";

import { Card, type CardProps } from "../../components/Card";

interface ShiftBottomSheetInnerCardProps extends CardProps {}

function BaseShiftBottomSheetInnerCard(
  props: ShiftBottomSheetInnerCardProps,
  ref: Ref<HTMLDivElement>
) {
  const { sx, ...restProps } = props;

  return <Card {...restProps} ref={ref} sx={mergeSxProps({ maxHeight: "100%" }, sx)} />;
}

export const ShiftBottomSheetInnerCard = forwardRef(BaseShiftBottomSheetInnerCard);
