import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase, Skeleton, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, type DateRange } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { CbhIcon } from "../../components/CbhIcon";
import { PillSkeleton } from "../../components/PillSkeleton";
import { DateWithDuration } from "../../DateTime/DateWithDuration";
import { SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { getFormattedShiftPayBreakdown } from "../getFormattedShiftPayBreakdown";
import { type TimeSlot } from "../Open/types";
import { WorkplaceNameWithPin } from "../Open/WorkplaceNameWithPin";
import { ShiftQualificationPill } from "../QualificationPill";
import { TimeSlotIcon } from "../TimeSlotIcon";
import { ShiftBottomSheetHeaderWrapper } from "./HeaderWrapper";

interface ShiftBottomSheetHeaderProps {
  timeSlot?: TimeSlot;
  qualificationName?: string;
  offerPay?: number;
  finalPay?: number;
  originalAmount?: number;
  dateRange?: DateRange;
  durationInHours?: number;
  workplaceId?: string;
  workplaceName?: string;
  timezone?: string;
  isLoading?: boolean;
}

export function ShiftBottomSheetHeader(props: ShiftBottomSheetHeaderProps) {
  const {
    timeSlot,
    qualificationName,
    offerPay,
    finalPay,
    originalAmount,
    dateRange,
    durationInHours,
    timezone,
    workplaceName,
    workplaceId,
    isLoading,
  } = props;

  const history = useHistory();

  const { generateModalPath } = useWorkplaceModalsDataContext();

  if (isLoading) {
    return (
      <ShiftBottomSheetHeaderWrapper>
        <PillSkeleton />
        <Stack>
          <Skeleton variant="text" width="70%" height="2rem" />
          <Skeleton variant="text" width="85%" height="2rem" />
          <Skeleton variant="text" width="65%" height="2rem" />
        </Stack>
      </ShiftBottomSheetHeaderWrapper>
    );
  }

  const payBreakdown = getFormattedShiftPayBreakdown(offerPay, finalPay, originalAmount);

  return (
    <ShiftBottomSheetHeaderWrapper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ paddingBottom: 3 }}
      >
        {isDefined(timeSlot) && <TimeSlotIcon timeSlot={timeSlot} size="large" />}
        {isDefined(qualificationName) && (
          <ShiftQualificationPill qualificationName={qualificationName} variant="outlined-dark" />
        )}
      </Stack>

      <Stack direction="row" alignItems="baseline" spacing={4}>
        <Text variant="h3">{payBreakdown?.totalPay}</Text>
        <Text variant="body1">{payBreakdown?.hourlyPay} /hr</Text>
      </Stack>

      <Box>
        {isDefined(dateRange) && isDefined(durationInHours) && (
          <DateWithDuration
            showTimeRange
            dateRange={dateRange}
            durationInHours={durationInHours}
            timezone={timezone}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        )}

        {isDefined(workplaceName) && isDefined(workplaceId) && (
          <ButtonBase
            sx={{
              width: "100%",
              alignItems: "center",
              py: 4,
              justifyContent: "flex-start",
            }}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                workplaceId,
                trigger: "Shift Bottom Sheet Header",
              });

              history.push(
                generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
                  workplaceId,
                })
              );
            }}
          >
            <WorkplaceNameWithPin workplaceName={workplaceName} textVariant="body1" />

            <CbhIcon type="chevron-right" size="xSmall" sx={{ marginLeft: 2 }} />
          </ButtonBase>
        )}
      </Box>
    </ShiftBottomSheetHeaderWrapper>
  );
}
