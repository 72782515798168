import { Stack } from "@mui/material";
import { type ReactNode } from "react";

interface ShiftBottomSheetHeaderWrapperProps {
  children: ReactNode;
}

export function ShiftBottomSheetHeaderWrapper(props: ShiftBottomSheetHeaderWrapperProps) {
  const { children } = props;

  return (
    <Stack alignItems="space-between" sx={{ paddingTop: 6, paddingBottom: 5, px: 7 }} spacing={4}>
      {children}
    </Stack>
  );
}
