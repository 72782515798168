import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { getDocumentCheckQualificationFromMultipleQualifications } from "@src/appV2/Accounts/Documents/helpers";
import { DocumentRequirementType, type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useShiftDiscoveryUserFiltersContext } from "@src/appV2/redesign/ShiftDiscovery/Filters/useUserFiltersContext";
import { SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { useHistory } from "react-router-dom";

import { LoadingButton } from "../../../components/LoadingButton";
import { isDocumentInstantReview } from "../../Bookability/Documents/isDocumentInstantReview";
import { isEmbeddedHelloSignEnabledForDocument } from "../../Bookability/Documents/isEmbeddedHelloSignEnabledForDocument";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";
import { ShiftBottomSheetMissingDocumentCardPill } from "./MissingDocumentCardPill";

interface ShiftBottomSheetMissingDocumentCardProps {
  shiftId: string;
  qualificationName: string;
  workplaceId?: string;
  document: HcpRequirement;
  isExpiring: boolean;
}

export function ShiftBottomSheetMissingDocumentCard(
  props: ShiftBottomSheetMissingDocumentCardProps
) {
  const { shiftId, qualificationName, workplaceId, document, isExpiring } = props;
  const history = useHistory();
  const { navigateToModal } = useShiftModalsDataContext();
  const { licenses: selectedLicensesFilter, availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  const isInstantReviewShiftEnabled = useCbhFlag(CbhFeatureFlag.INSTANT_REVIEW_SHIFTS, {
    defaultValue: false,
  });

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  const isInstantReviewDocument = isDocumentInstantReview({
    document,
    isHelloSignEmbeddedFlowEnabled,
  });

  const isEmbeddedHelloSignDocument = isEmbeddedHelloSignEnabledForDocument({
    document,
    isHelloSignEmbeddedFlowEnabled,
  });

  const onClick = () => {
    const isInstantReview = isInstantReviewDocument && isInstantReviewShiftEnabled;

    logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CARD_CTA_BLOCKING_DOCUMENT, {
      shiftId,
      requirementId: document.reqId,
      isInstantReview,
      isMissing: !isExpiring,
      isExpiring,
    });

    if (isInstantReview) {
      navigateToModal(SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH, {
        requirementId: document.reqId,
        shiftId,
      });
    } else {
      const documentDetailsPath = generateDocumentDetailsPath({
        hcfId: workplaceId,
        requirement: document.reqId,
        requirementStatus: DocumentRequirementType.MISSING,
      });

      history.push(documentDetailsPath, {
        qualification: getDocumentCheckQualificationFromMultipleQualifications({
          selectedWorkerQualifications:
            selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses,
          shiftQualificationRequirement: qualificationName,
        }),
      });
    }
  };

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title={document.name}
      description={
        isInstantReviewDocument
          ? "This document will be reviewed instantly."
          : `Please ${
              isEmbeddedHelloSignDocument ? "sign" : "complete"
            } this requirement before booking this shift.`
      }
      illustrationType={isEmbeddedHelloSignDocument ? "quiz-required" : "document-upload"}
      pill={
        <ShiftBottomSheetMissingDocumentCardPill
          isInstantReviewDocument={isInstantReviewDocument}
          isExpiring={isExpiring}
          document={document}
        />
      }
      footer={
        <LoadingButton fullWidth size="medium" isLoading={false} onClick={onClick}>
          {isEmbeddedHelloSignDocument ? "Sign" : "Upload"}
        </LoadingButton>
      }
    />
  );
}
