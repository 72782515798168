import { isDefined } from "@clipboard-health/util-ts";
import { Skeleton } from "@mui/material";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";
import { useInView } from "react-intersection-observer";

import { MosaicGallery, type MosaicGalleryItem } from "../../components/MosaicGallery";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetFacilityPhotosCardProps {
  workplaceId?: string;
}

export function ShiftBottomSheetFacilityPhotosCard(props: ShiftBottomSheetFacilityPhotosCardProps) {
  const { workplaceId } = props;

  const { ref, inView } = useInView();

  const { data, isSuccess, isLoading } = useFacilityPhotos(
    {
      facilityId: workplaceId ?? "",
    },
    {
      enabled: inView && isDefined(workplaceId) && workplaceId !== "",
    }
  );

  const photos = data?.photosList ?? [];
  const images = photos
    .map((photo) => {
      if (!photo.url) {
        return null;
      }

      return {
        src: photo.url,
        alt: photo.name ?? `facility-photo-${photo.index}`,
      };
    })
    .filter((image): image is MosaicGalleryItem => image !== null);

  if (isSuccess && images.length === 0) {
    return null;
  }

  if (isLoading && images.length === 0) {
    return (
      <ShiftBottomSheetInnerCard ref={ref}>
        <Skeleton variant="rounded" width="100%" height="100%" />
      </ShiftBottomSheetInnerCard>
    );
  }

  return (
    <ShiftBottomSheetInnerCard ref={ref}>
      <MosaicGallery images={images} />
    </ShiftBottomSheetInnerCard>
  );
}
