import { isDefined } from "@clipboard-health/util-ts";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { DocumentStatusPill } from "@src/appV2/redesign/Documents/components/StatusPill";
import { parseISO } from "date-fns";

import { QuickSignPill } from "../../Bookability/Documents/InstantReview/QuickSignPill";

interface ShiftBottomSheetMissingDocumentCardPillProps {
  isInstantReviewDocument: boolean;
  isExpiring: boolean;
  document: HcpRequirement;
}

export function ShiftBottomSheetMissingDocumentCardPill(
  props: ShiftBottomSheetMissingDocumentCardPillProps
) {
  const { isInstantReviewDocument, isExpiring, document } = props;
  if (isInstantReviewDocument) {
    return <QuickSignPill />;
  }

  if (isExpiring && isDefined(document.expiry)) {
    return (
      <DocumentStatusPill
        size="medium"
        variant="expiring_soon"
        expiry={parseISO(document.expiry)}
      />
    );
  }

  return null;
}
